.introduction__container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
    
}

.introduction__image {
    width: 100vw;
    height: 100vh;
    flex-shrink: 0;
    overflow: hidden; 
    position: sticky;
    object-fit: cover;
    scroll-snap-align: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2); 
        z-index: 0;
    }
}


.introduction__content-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    scroll-snap-type: x mandatory;
    box-shadow: 0px 3px 6px #00000026;
    position: relative;
    overflow: hidden;
    z-index: 2;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
    
}

.introduction__image {
    width: 100vw;
    height: 100vh;
    flex-shrink: 0;
    overflow: hidden; 
    position: sticky;
    object-fit: cover;
    scroll-snap-align: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2); 
        z-index: 0;
    }
}

@keyframes pulse {
    0% {
        opacity: 0;
        transform: translateY(10rem);
    }
    40% {
        opacity: 0;
        transform: translateY(10rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0rem);
    }
}

.introduction__info {
    width: 80%;
    text-transform: uppercase;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 20vh;
    bottom: 0;
    margin: auto;

    & > h1 {
        font-size: 62px;
        font-weight: 800;
        letter-spacing: 5px;
        margin-bottom: 0rem;

        @include xxl {

            @include md-h {
                font-size: 50px;
            }
            @include sm-h {
                font-size: 30px;
                margin-bottom: 3rem;
            }
            @include xs-h {
                font-size: 20px;
            }
        }
    
        @include md {
            font-size: 60px;

            @include sm-h {
                font-size: 18px;
                margin-bottom: 0rem;
            }

        }
    
        @include sm {
            font-size: 30px;
        }
    
        @include xs {
            font-size: 30px;
        }
    }

    @include lg {
        width: 75%;
    }

    @include md {
        width: 80%;
        margin-top: 10vh;

        @include sm-h {
            margin-top: 0vh;
        }
    }

}

.introduction__info.active {
    & > h1 {
        animation: pulse 1.25s ease;
    }
}

//


.introduction__info {
    width: 80%;
    text-transform: uppercase;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 20vh;
    bottom: 0;
    margin: auto;

    & > h1 {
        font-size: 62px;
        font-weight: 800;
        letter-spacing: 5px;
        margin-bottom: 0rem;

        @include xxl {

            @include md-h {
                font-size: 50px;
            }
            @include sm-h {
                font-size: 30px;
                margin-bottom: 3rem;
            }
            @include xs-h {
                font-size: 20px;
            }
        }
    
        @include md {
            font-size: 60px;

            @include sm-h {
                font-size: 18px;
                margin-bottom: 0rem;
            }

        }
    
        @include sm {
            font-size: 30px;
        }
    
        @include xs {
            font-size: 30px;
        }
    }

    @include lg {
        width: 75%;
    }

    @include md {
        width: 80%;
        margin-top: 10vh;

        @include sm-h {
            margin-top: 0vh;
        }
    }

}

.introduction__info.active {
    & > h1 {
        animation: pulse 1.25s ease;
    }
}


//


.introduction__brands {
    width: 200px;
    height: auto;
    object-fit: cover;
    position: absolute;
    right: 15vh;
    bottom: 5vh;
}

.introduction__brands.active {
    animation: pulse 1.25s ease;
}

//

.introduction__scroll-container {
    position: fixed;
    right: 3rem;
    top: 0px;
    bottom: 0px;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    display: none;
    & > h2 {
        color: white;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 200;
        line-height: 15px;
        text-transform: uppercase;
        display: block;
    }

    & > h2 > span {
        display: block;
        margin-bottom: 5px; /* Ajusta el espacio entre las letras según sea necesario */
      }

      @include sm {
        right: 2rem;

        & > h2 {
            color: white;
            text-align: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 200;
            line-height: 15px;
            text-transform: uppercase;
            display: block;
        }
    }

    @include xs {
        right: 1rem;
    }
}


.introduction__scroll-arrow span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.introduction__scroll-arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.introduction__scroll-arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

// SCROLL ARROW

.introduction__scroll-container {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 10vh;
    height: fit-content;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    bottom: -3rem;
    display: none;
}


.introduction__scroll-arrow-go-left {
    position: relative;
    cursor: pointer;
}

.introduction__scroll-arrow-go-right {
    position: relative;
    cursor: pointer;

}


@keyframes animateGoLeft {
    0% {
        transform: rotate(-225deg) translate(-5px, -5px);
    }
    50% {
        transform: rotate(-225deg) translate(5px, 5px);
    }
    100% {
        transform: rotate(-225deg) translate(-5px, -5px);
    }
}

@keyframes animateGoRight {
    0% {
        transform: rotate(-45deg) translate(-5px, -5px);
    }
    50% {
        transform: rotate(-45deg) translate(5px, 5px);
    }
    100% {
        transform: rotate(-45deg) translate(-5px, -5px);
    }
}

.introduction__scroll-arrow-go-left span {
    display: block;
    width: 2rem;
    height: 2rem;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    animation: animateGoLeft 3s ease-in-out infinite;

    @include xxl {

        @include sm-h {
            width: 1rem;
            height: 1rem;
        }

    }

    @include md {
        width: 1rem;
        height: 1rem;

    }

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.introduction__scroll-arrow-go-right span {
    display: block;
    width: 2rem;
    height: 2rem;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    animation: animateGoRight 3s ease-in-out infinite;

    @include xxl {

        @include sm-h {
            width: 1rem;
            height: 1rem;
        }

    }

    @include md {
        width: 1rem;
        height: 1rem;
    }

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.introduction__works-counter {
    position: absolute;
    right: 0rem;
    left: 0px;
    bottom: 50px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @include xxl {

        @include sm-h {
            bottom: 20px;
        }
    }

    @include md {

        @include sm-h {
            bottom: 20px;
        }

    }

}

.introduction__hr-counter {
    width: 30px;
    height: 1px;
    background-color: white;
    border-radius: 50px;
    margin: 0px 20px;

    &:last-of-type {
        visibility: hidden;
        position: absolute;
    }

    @include xs {
        width: 20px;

    }
}

.introduction__work-indicator {
    width: 10px;
    height: 10px;
    color: white;
    font-size: 14px;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    cursor: pointer;

}

.introduction__work-indicator-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5rem;
    width: 15px;
    height: 15px;
    
    &::before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        border-radius: 5rem;
        margin: auto;
        border: #fff 1px solid;
        z-index: 2;


    }

}